// ------------------------ COLORS ------------------------------

// TODO: Перейти на использование глобальных переменных

// ------------------------ PAGE -----------------------------
$color-accent: var(--tui-primary);
$color-default: var(--unions-color-default);
$color-primary: var(--unions-color-primary);
$color-primary-pale: var(--unions-color-primary-pale);
$color-primary-light: var(--unions-color-primary-light);
$color-primary-hover: var(--tui-primary-hover);
$color-primary-active: var(--tui-primary-active);
$color-secondary: var(--tui-accent);
$color-accent-hover: var(--tui-accent-hover);
$color-selected: var(--unions-color-selected);
$color-hover: var(--unions-color-hover);
$color-main-background: var(--tui-base-02);
$color-tooltip-background: var(--unions-color-tooltip-background-90);
$color-header-background: var(--unions-color-header-background);
$color-link: var(--unions-color-link);
$color-link-new: var(--tui-link);
$color-link-command: var(--unions-color-link-command);
$color-link-02: var(--unions-color-link-02);
$color-wrapper-hover: var(--unions-color-wrapper-hover);
$color-wrapper-pressed: var(--unions-color-wrapper-pressed);
$color-scroll: var(--unions-color-scroll);
$color-dictionary-icon: var(--unions-color-dictionary-icon);
$color-background-new: var(--unions-color-background-new);
$color-page-background: var(--unions-color-page-background);
$color-lp-product-background: var(--unions-color-lp-product-background);
$color-lp-product-archive-background: var(--unions-color-lp-product-archive-background);
$color-lp-dialog-background: var(--unions-color-lp-dialog-background);
$color-lp-product-wizard-current-background: var(--unions-color-lp-product-wizard-current-background);
$color-count-icon: var(--unions-color-count-icon);
$color-grey-button: var(--unions-color-grey-button);
$color-textfield-background: var(--unions-color-textfield-background);
$color-shadow: var(--unions-color-shadow);
$color-button-default: var(--unions-color-button-default);
$color-icon: var(--unions-color-icon);

// ------------------------ BASE -----------------------------
$color-white: var(--tui-base-01);
$color-grey-light: var(--tui-base-03);
$color-base-04: var(--tui-base-04);
$color-base-05: var(--tui-base-05);
$color-base-09: var(--tui-base-09);
$color-black: var(--tui-text-01);
$color-grey: var(--unions-color-grey);
$color-grey-dark: var(--unions-color-grey-dark);

// ------------------------ SUPPORT -----------------------------
$color-red: var(--unions-color-red);
$color-green: var(--unions-color-green);
$color-green-dark: var(--unions-color-green-dark);
$color-blue: var(--unions-color-blue);
$color-blue-dark: var(--unions-color-blue-dark);
$color-blue-light: var(--unions-color-blue-light);
$color-pink: var(--unions-color-pink);
$color-orange: var(--unions-color-orange);
$color-support-01: var(--tui-support-01);
$color-support-05: var(--tui-support-05);
$color-support-06: var(--tui-support-06);
$color-support-11: var(--tui-support-11);
$color-support-08: var(--tui-support-08);
$color-unions-support-08: var(--unions-color-support-08);
$color-support-22: var(--unions-color-support-22);
$color-support-23: var(--unions-color-support-23);
$color-support-24: var(--unions-color-support-24);

// ------------------------ TEXT ------------------------------
$color-text-primary: var(--unions-color-text-primary);
$color-text-secondary: var(--unions-color-text-secondary);
$color-accent-text: var(--tui-accent-text);
$color-text-01: var(--unions-color-text-01-90);
$color-text-02: var(--tui-text-02);
$color-text-03: var(--tui-text-03);
$color-text-04: var(--unions-color-text-04);
$color-text-05: var(--unions-color-text-05);
$color-text-06: var(--unions-color-text-06);
$color-text-07: var(--unions-color-text-07);
$color-text-08: var(--unions-color-text-08);
$color-text-09: var(--unions-color-text-09);
$color-text-grey: var(--unions-color-text-grey);
$color-text-card-name: var(--unions-color-text-card-name);
$color-text-badge: var(--unions-color-text-badge);

// ------------------------ STATUS -----------------------------
$color-mute: var(--unions-color-mute);
$color-disabled: var(--unions-color-disabled);
$color-status-disabled: var(--unions-color-status-disabled);
$color-error: var(--unions-color-error);
$color-error-fill: var(--tui-error-fill);
$color-status-info-bg-hover: var(--tui-info-bg-hover);
$color-status-warning-bg-hover: var(--tui-warning-bg-hover);
$color-success-fill: var(--tui-success-fill);
$color-success-bg: var(--tui-success-bg);
$color-neutral-fill-night: var(--tui-neutral-fill-night);
$color-neutral-bg-hover: var(--tui-neutral-bg-hover);
$color-none-background: var(--unions-color-none-background);
$color-negative: var(--tui-negative);

$border: 0.3px solid var(--tui-base-05);

// ------------------------ MIX -----------------------------
$color-shadow-8: var(--unions-color-shadow-8);
$color-textfield-background-12: var(--unions-color-textfield-background-12);
$color-text-09-60: var(--unions-color-text-09-60);
$color-base-09-5: var(--unions-color-base-09-5);
$color-base-09-12: var(--unions-color-base-09-12);
$color-base-09-50: var(--unions-color-base-09-50);
$color-accent-45: var(--unions-color-accent-45);
$color-accent-65: var(--unions-color-accent-65);
$color-accent-70: var(--unions-color-accent-70);
$color-black-30: var(--unions-color-black-30);
$color-black-40: var(--unions-color-black-40);
$color-grey-light-25: var(--unions-color-grey-light-25);
$color-blue-30: var(--unions-color-blue-30);
$color-support-22-30: var(--unions-color-support-22-30);
$color-support-22-50: var(--unions-color-support-22-50);
$color-grey-75: var(--unions-color-grey-75);
$color-grey-85: var(--unions-color-grey-85);
$color-white-8: var(--unions-color-white-8);
$color-white-color-base-09-14: var(--unions-color-white-color-base-09-14);
$color-white-color-base-09-5: var(--unions-color-white-color-base-09-5);
$color-link-new-color-base-09-10: var(--unions-color-link-new-color-base-09-10);
$color-link-new-color-base-09-20: var(--unions-color-link-new-color-base-09-20);
$color-green-color-white-10: var(--unions-color-green-color-white-10);
$color-green-color-color-base-09-10: var(--unions-color-green-color-color-base-09-10);
$color-green-color-color-base-09-20: var(--unions-color-green-color-color-base-09-20);

// ------------------------ FONTS -------------------------------
$font-family-base: 'Roboto', 'Helvetica Neue', sans-serif;

$font-size-h1: 56px;
$font-height-h1: 1.4;

$font-size-h2: 48px;
$font-height-h2: 1.45;

$font-size-h3: 36px;
$font-height-h3: 1.5;

$font-size-h4: 32px;
$font-height-h4: 1.55;

$font-size-h5: 26px;
$font-height-h5: 1.6;

$font-size-largest: 22px;
$font-height-largest: 1.52;

$font-size-larger: 18px;
$font-height-larger: 1.52;

$font-size-large: 16px;
$font-height-large: 1.54;

$font-size-medium: 14px;
$font-height-medium: 1.56;

$font-size-small: 12px;
$font-height-small: 1.6;

$font-size-minimal: 10px;
$font-height-minimal: 1.6;

$font-weight-slim: 200;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-bolder: 800;

// ---------------------- BREAKPOINTS ---------------------------

// keep it equal with enum eBreakpoints
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1280px;
$screen-xxl: 1920px;

$screen-xs-max: 575px;
$screen-sm-max: 767px;
$screen-md-max: 1023px;
$screen-lg-max: 1279px;
$screen-xl-max: 1919px;

// ---------------------- OTHER ---------------------------

$default-radius: 6px;
$field-radius: 12px;
